var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-breadcrumbs',{staticClass:"pa-0",attrs:{"items":_vm.breadcrumbs,"large":""}})],1)],1),_c('v-row',[_c('v-col',[_c('wolk-private-data-alert')],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" Events "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Zoeken","single-line":"","hide-details":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"options":_vm.tableSort,"items":_vm.filteredEvents,"items-per-page":20,"footer-props":{ itemsPerPageOptions: [10, 20, 50, 200] },"loading":_vm.loading},on:{"update:options":function($event){_vm.tableSort=$event}},scopedSlots:_vm._u([{key:"item.wolk.metaData.sleevePublicId",fn:function(ref){
var item = ref.item;
return [(item.wolk && item.wolk.metaData && item.wolk.metaData.sleevePublicId)?_c('router-link',{attrs:{"to":{ name: 'wolksShow', params: { id: item.wolk._id }}}},[_vm._v(" "+_vm._s(item.wolk.metaData.sleevePublicId)+" ")]):_vm._e()]}},{key:"item.organization.title",fn:function(ref){
var item = ref.item;
return [(item.organization && item.organization.title)?_c('router-link',{attrs:{"to":{ name: 'organizationsShow', params: { id: item.organization._id }}}},[_vm._v(" "+_vm._s(item.organization.title)+" ")]):_vm._e()]}},{key:"item.location.title",fn:function(ref){
var item = ref.item;
return [(item.location)?_c('router-link',{attrs:{"to":{ name: 'locationsShow', params: { id: item.location._id }}}},[_vm._v(" "+_vm._s(item.location.title)+" ")]):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getHumanizedDate(item.createdAt))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getType(item))+" ")]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }