<template>
  <v-container>
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0"
          large
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <wolk-private-data-alert />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Events

            <v-spacer />

            <v-text-field
              v-model="filter"
              append-icon="mdi-magnify"
              label="Zoeken"
              single-line
              hide-details
            />

            <v-spacer />
          </v-card-title>

          <v-data-table
            :headers="headers"
            :options.sync="tableSort"
            :items="filteredEvents"
            :items-per-page="20"
            :footer-props="{ itemsPerPageOptions: [10, 20, 50, 200] }"
            :loading="loading"
          >
            <template #[`item.wolk.metaData.sleevePublicId`]="{ item }">
              <router-link
                v-if="item.wolk && item.wolk.metaData && item.wolk.metaData.sleevePublicId"
                :to="{ name: 'wolksShow', params: { id: item.wolk._id }}"
              >
                {{ item.wolk.metaData.sleevePublicId }}
              </router-link>
            </template>

            <template #[`item.organization.title`]="{ item }">
              <router-link
                v-if="item.organization && item.organization.title"
                :to="{ name: 'organizationsShow', params: { id: item.organization._id }}"
              >
                {{ item.organization.title }}
              </router-link>
            </template>

            <template #[`item.location.title`]="{ item }">
              <router-link
                v-if="item.location"
                :to="{ name: 'locationsShow', params: { id: item.location._id }}"
              >
                {{ item.location.title }}
              </router-link>
            </template>

            <template #[`item.createdAt`]="{ item }">
              {{ getHumanizedDate(item.createdAt) }}
            </template>

            <template #[`item.type`]="{ item }">
              {{ getType(item) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

import {
  getHumanizedDate,
} from '../helpers/humanizer';

import WolkPrivateDataAlert from '../components/WolkPrivateDataAlert.vue';

export default {
  components: {
    WolkPrivateDataAlert,
  },
  data() {
    return {
      filter: '',
      loading: false,
      tableSort: {
        page: 1,
        itemsPerPage: 20,
        sortBy: ['createdAt'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: true,
      },
      headers: [
        { text: 'Wolk', value: 'wolk.metaData.sleevePublicId', sortable: false },
        { text: 'Organisatie', value: 'organization.title', sortable: false },
        { text: 'Locatie', value: 'location.title', sortable: false },
        { text: 'Created at', value: 'createdAt' },
        { text: 'type', value: 'type', sortable: false },
      ],
      events: [],
      breadcrumbs: [
        {
          text: 'Wolk dashboard',
          disabled: false,
          exact: true,
          to: { name: 'home' },
        },
        {
          text: 'Events',
          disabled: true,
          exact: true,
          to: { name: 'events' },
        },
      ],
    };
  },
  computed: {
    filteredEvents() {
      if (!this.filter) {
        return this.events;
      }

      return this.events.filter((c) => c._id.toLowerCase().includes(this.filter.toLowerCase()));
    },
  },
  beforeMount() {
    this.loadEvents();
  },
  methods: {
    getHumanizedDate(millis) {
      return getHumanizedDate(millis);
    },
    getType(event) {
      if (event.type) {
        return event.type;
      }

      if (event.heartbeat) {
        return 'heartbeat';
      }

      return 'fallevent';
    },
    loadEvents() {
      this.loading = true;
      const url = `${config.VUE_APP_API_BASE_URL}/events`;

      axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'get',
      })
        .then((response) => {
          this.events = response.data.events.map((e) => ({
            ...e,
            location: response
              .data
              .locations
              .find((l) => l._id === e.legacyReferences.locationId),
            organization: response
              .data
              .organizations
              .find((l) => l._id === e.legacyReferences.organizationId),
            wolk: response
              .data
              .wolks
              .find((l) => l._id === e.legacyReferences.wolkId),
          }));
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
