<template>
  <v-alert
    border="bottom"
    colored-border
    type="warning"
    elevation="2"
    class="mb-0"
  >
    Let op vertrouwelijke informatie. Niet delen met onbevoegden.
  </v-alert>
</template>

<script>
export default {
  name: 'WolkPrivateDataAlert',
};
</script>
